import './App.css';
import { Routes, Route } from 'react-router-dom';
import { Home } from './components/home/Home';
import { About } from './components/about-us/About';
import { ContactUs } from './components/contact-us/ContactUs';
import Details from './components/services/individual/Details';
import Service from './components/services/Service';
import Astrology from './components/astrology/Astrology';
import { useEffect } from 'react';
import ReactGA from 'react-ga';
import { TRACKER_ID } from './constants/global';

ReactGA.initialize(TRACKER_ID);
function App() {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);
  return (
    <>
      <div className="App" />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/aboutUs" element={<About />} />
        <Route path="/contactUs" element={<ContactUs />} />
        <Route path="/astrology" element={<Astrology />} />
        <Route path="services/:serviceName" element={<Details />} />
        <Route path="services" element={<Service />} />
      </Routes>
    </>
  );
}
export default App;
