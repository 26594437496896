import React, { Component } from 'react';

export class Carousel extends Component {
    render() {
        return (
            <>{/* Carousel Start */}
                <div className="carousel">
                    <div className="container-fluid">
                        <div className="owl-carousel">
                            <div className="carousel-item">
                                <div className="carousel-img">
                                    <img src="assets/img/carousel-1.jpg" alt="Carousel 1" />
                                </div>
                                <div className="carousel-text">
                                    <h3>&nbsp;</h3>
                                    <h1>&nbsp;</h1>
                                    <p>
                                        &nbsp;
                                    </p>

                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                {/* Carousel End */}</>
        )
    }
}

export default Carousel;