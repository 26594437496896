import React, { Component } from 'react';
import Carousel from "./Carousel";
import Services from "./Services";
import { Testimonial } from '../testimonials/Testimonial';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { homeMetaData } from '../../constants/global';

export class Home extends Component {
    constructor(props) {
        super(props);
        this.metaKeywords = homeMetaData.metaKeywords;
        this.metaDescription = homeMetaData.metaDescription;
    }
    componentDidMount() {
        this.loadScript();
    }

    componentDidUpdate(prevProps) {
        // Check if the component is navigating back to the same page
        if (prevProps.location.pathname !== this.props.location.pathname) {
            // Remove the previous script before loading a new one
            this.removeScript();
            this.loadScript();
        }
    }

    componentWillUnmount() {
        this.removeScript();
    }

    loadScript() {
        const script = document.createElement('script');
        script.src = '/assets/js/main.js';
        script.async = true;
        document.body.appendChild(script);
        this.scriptElement = script;
    }

    removeScript() {
        if (this.scriptElement) {
            document.body.removeChild(this.scriptElement);
            this.scriptElement = null;
        }
    }

    render() {
        return (
            <> <HelmetProvider>
                <Helmet>
                    <meta name="keywords" content={this.metaKeywords} />
                    <meta name="description" content={this.metaDescription} />
                </Helmet>
                <Carousel></Carousel>
                <Services></Services>
                <Testimonial    ></Testimonial>
            </HelmetProvider>
            </>
        );
    }
}

export default Home;