import * as React from "react";
import createClass from 'create-react-class';

const Map = createClass({
    render: function () {
        return (
            <iframe
                src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3561.5835552305407!2d75.75804011504229!3d26.78954158318091!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMjbCsDQ3JzIyLjQiTiA3NcKwNDUnMzYuOCJF!5e0!3m2!1sen!2sin!4v1684392882852!5m2!1sen!2sin"
                frameBorder={0} style={{ border: 0 }} allowFullScreen aria-hidden="false" tabIndex={0} title="Google Map" />
        );
    }
});

export default Map;