export const serviceComments = {
    "akhandRamayanPath": [{
        name: "Vijay",
        createdAt: "01 Jan 2023 at 10:00am",
        message: "The Akhand Ramayan Path conducted by Pandit Murli Shashri was a spiritually uplifting experience. It brought a sense of peace and devotion to our home. Highly recommended!"
    }],
    "annaprashanSanskar": [],
    "ayudhaPuja": [],
    "bhoomiPuja": [],
    "birthdayPuja": [],
    "chandiHoma": [],
    "dhanterasPuja": [],
    "diwaliLakshmiPuja": [],
    "ganeshaChaturthiPuja": [],
    "gayatriJapa": [],
    "grahaDoshNivaranPuja": [],
    "grihaPraveshaPuja": [],
    "kalasaPradoshaPuja": [],
    "lakshmiPuja": [],
    "lastRites": [],
    "mahaGanapatiPujaAndHoma": [],
    "mahaMrityunjayaHoma": [],
    "marriagePuja": [],
    "mulaNakshatraShantiPuja": [],
    "namakaranPuja": [],
    "navagrahaPujaAndHoma": [],
    "navarathriPath": [],
    "officeOpeningOrNewBusinessPuja": [],
    "rudrabhishekPuja": [],
    "sathyanarayanPuja": [],
    "sudarshanaHoma": [],
    "sunderkandPath": [],
    "varaMahalakshmiPuja": {

    }
}