import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import './i18n';
import reportWebVitals from './reportWebVitals';
import Layout from './components/layout/Layout';
import { BrowserRouter } from 'react-router-dom';
import ScrollToTop from './ScrollToTop';
import ReactGA from "react-ga";
import { TRACKER_ID } from './constants/global';

const root = ReactDOM.createRoot(document.getElementById('root'));
//Initialize GA4
ReactGA.initialize(TRACKER_ID);
root.render(
    <React.StrictMode>
        <Suspense fallback='loading'>
            <BrowserRouter>
                <ScrollToTop></ScrollToTop>
                <Layout>
                    <App />
                </Layout>
            </BrowserRouter>
        </Suspense>
    </React.StrictMode>
);
const SendAnalytics = () => {
    ReactGA.send({
        hitType: "pageview",
        page: window.location.pathname,
    });
}
reportWebVitals(SendAnalytics);
