import { t } from 'i18next'
import React, { Component } from 'react'
import { siteName } from '../../constants/global'
import { Helmet, HelmetProvider } from 'react-helmet-async'

export class About extends Component {
  render() {
    return (
      <>
        <HelmetProvider>
          <Helmet>
            <title>{t('navigation.aboutUs')} | {siteName}</title>
          </Helmet>
          <div>
            <div className="about">
              <div className="container">
                <div className="row align-items-center">
                  <div className="col-lg-6">
                    <div className="about-img">
                      <img src="assets/img/about.jpg" alt="abouimg" />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="section-header text-left">
                      <p>About Us</p>
                      <h2>Pandit Murli</h2>
                    </div>
                    <div className="about-content">
                      <div>
                        <div dangerouslySetInnerHTML={{ __html: t("aboutUs.description") }} />
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </HelmetProvider>
      </>
    )
  }
}

export default About
