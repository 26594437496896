import React from "react";
import ServiceSelect from "../service-select/service-select";

function RequestForm(props) {
    const { serviceName } = props || '';
    function submit(e) {
        e.preventDefault();
    }
    return (<>
        <div className="comment-form">
            <h3>Book Your Pandit ji:</h3>
            <form onSubmit={submit}>
                <div className="form-group">
                    <label htmlFor="name">Name *</label>
                    <input type="text" className="form-control" id="name" />
                </div>
                <div className="form-group">
                    <label htmlFor="mobile">mobile *</label>
                    <input type="mobile" className="form-control" id="mobile" />
                </div>
                <div className="form-group">
                    <label htmlFor="service">Service *</label>
                    <ServiceSelect serviceName={serviceName}></ServiceSelect>
                </div>
                <div className="form-group">
                    <label htmlFor="date">Date *</label>
                    <input type="date" className="form-control" id="date"
                        placeholder="Date" required="required"
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="message">Message *</label>
                    <textarea id="message" cols="30" rows="5" className="form-control"></textarea>
                </div>
                <div className="form-group">
                    <input type="submit" value="Request" className="btn btn-custom" />
                </div>
            </form>
        </div>
    </>)
}
export default RequestForm;