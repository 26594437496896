import { t } from "i18next";
import { services } from "../../../constants/global";
import { useState } from "react";

export default function ServiceSelect(props = '') {
    const { serviceName } = props || "";
    const [selected, setSelected] = useState(serviceName);
    const handleChange = event => {
        setSelected(event.target.value);
    };
    return (
        <select className="form-control" value={selected} id="service" onChange={handleChange} >
            <option value="">Select Prayer Service</option>
            {
                Object.keys(services).map((service, index) => {
                    return (
                        <option value={service} key={index}>
                            {t(`services.${service}.name`)}
                        </option>
                    );
                })
            }
        </select>
    );
}