import { t } from 'i18next'
import React, { Component } from 'react'
import RequestForm from '../common/request-form/RequestForm'
import { astrologyMetaData, siteName } from '../../constants/global'
import { Helmet, HelmetProvider } from 'react-helmet-async'

export default class Astrology extends Component {
  constructor(props) {
    super(props);
    this.metaKeywords = astrologyMetaData.metaKeywords;
    this.metaDescription = astrologyMetaData.metaDescription;
  }
  render() {
    return (
      <>
        <HelmetProvider>
          <Helmet>
            <title>{t('navigation.astrologyServices')} | {siteName}</title>
            <meta name="keywords" content={this.metaKeywords} />
            <meta name="description" content={this.metaDescription} />
          </Helmet>
          <div className="single">
            <div className="container">
              <div className="row">
                <div className="col-lg-8">
                  <div className="section-header text-left">
                    <h2>{t('navigation.astrologyServices')}</h2>
                  </div>
                  <div className="about-content">
                    <div>
                      <div dangerouslySetInnerHTML={{ __html: t("astrology.description") }} />
                    </div>

                  </div>
                </div>
                <div className="col-lg-4">
                  <RequestForm></RequestForm>
                </div>
              </div>
            </div>
          </div>
        </HelmetProvider>
      </>
    )
  }
}
