import { t } from 'i18next';
import { services } from '../../constants/global';
import ServiceShort from '../services/ServiceShort';
import { Link } from 'react-router-dom';
import { serviceNameToUrl } from '../../services/common';

function Services() {
    const servicesLength = 8;
    return (
        <div className="service">
            <div className="container">
                <div className="section-header text-center">
                    <p>{t('sevicesParagraph')}</p>
                    <h2>{t('navigation.prayerServices')}</h2>
                </div>
                <div className="row">
                    {
                        Object.keys(services).map((service, index) => {
                            if (servicesLength > index) {
                                return (

                                    <div className="col-lg-3 col-md-6" key={service}>
                                        <ServiceShort link={'/services/' + serviceNameToUrl(service)} name={t(`services.${service}.name`)} shortDescription={t(`services.${service}.short`)} />
                                    </div>
                                );
                            }
                            return false;
                        })
                    }

                </div>
                <div className='row  justify-content-center'><Link className="btn btn-custom center" to="/services">View All Services</Link></div>
            </div>
        </div>
    );
}
export default Services;
