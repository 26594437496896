import { useParams } from "react-router-dom";
import { UrlToServiceName } from "../../../services/common";
import { t } from "i18next";
import { defaultPrayerImage, services, siteName } from "../../../constants/global";
import Comments from "../../common/comment/Comments";
import RequestForm from "../../common/request-form/RequestForm";
import { Helmet, HelmetProvider } from "react-helmet-async";


function Details(props) {
    const { serviceName } = useParams();
    let serviceKey = "";
    try {
        serviceKey = UrlToServiceName(serviceName);
    } catch (error) {

    }
    const name = t(`services.${serviceKey}.name`);
    const description = t(`services.${serviceKey}.description`);
    const serviceImage = services[serviceKey].img;
    const metaKeywords = services[serviceKey].metaKeywords;
    const metaDescription = services[serviceKey].metaDescription;
    return (<>
        <HelmetProvider>
            <Helmet>
                <title>{name} | {siteName}</title>
                <meta name="keywords" content={metaKeywords} />
                <meta name="description" content={metaDescription} />
            </Helmet>

            <div className="single">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8">
                            <div className="single-content">
                                <h2>{name}</h2>
                                <img src={serviceImage} alt={name} onError={({ currentTarget }) => {
                                    currentTarget.onerror = null; // prevents looping
                                    currentTarget.src = defaultPrayerImage;
                                }} />

                                <div dangerouslySetInnerHTML={{ __html: description }} />
                            </div>

                            {/* <RelatedPost></RelatedPost> */}
                            <Comments serviceKey={serviceKey}></Comments>
                        </div>

                        <div className="col-lg-4">
                            <RequestForm serviceName={serviceKey}></RequestForm>
                        </div>
                    </div>
                </div>
            </div>
        </HelmetProvider>
    </>)
}
export default Details;