export const siteName = "Pt. Murli";
export const mobile = "+91 99287 07701";
export const email = "admin@panditmurli.com";
export const companyName = "Hamari Dairy";
export const TRACKER_ID = "G-RXSQ0HH7M8";
export const companySite = "https://hamaridairy.com";
export const defaultPrayerImage = '/assets/img/default-prayer.jpg';
export const homeMetaData = {
    "metaKeywords": "Pandit Murli Shashtri, Astrology Services, Vedic Astrology, Puja Services, Akhand Ramayan Path, Annaprashan Sanskar, Ayudha Puja, Bhoomi Puja, Chandi Homa, Dhanteras Puja",
    "metaDescription": "Explore the world of astrology with Pandit Murli Shashtri, an experienced astrologer in Jaipur. Discover the power of Vedic astrology and unlock insights about your life through accurate birth chart analysis, personalized horoscope readings, and astrological predictions. Gain a deeper understanding of yourself, your relationships, and your future with the guidance of Pandit Murli Shashtri."
};
export const aboutUsMetaData = {
    "metaKeywords": "Pandit Murli Shashtri, Astrologer in Jaipur, Vedic Astrology, Spiritual Services, Puja Ceremonies, Experienced Astrologer",
    "metaDescription": "Learn about Pandit Murli Shashtri, a renowned astrologer in Jaipur, known for his expertise in Vedic astrology and spiritual services. With years of experience, Pandit Murli Shashtri offers a range of services such as Akhand Ramayan Path, Annaprashan Sanskar, Ayudha Puja, and more. Discover the profound knowledge and guidance provided by Pandit Murli Shashtri to help you navigate through life's challenges."
};
export const contactUsMetaData = {
    "metaKeywords": "Contact Pandit Murli Shashtri, Contact Pandit Ji in Jaipur, Contact Astrologer, Get in Touch, Consultation, Book Appointment",
    "metaDescription": "Contact Pandit Murli Shashtri in Jaipur for astrology services, puja ceremonies, and spiritual guidance. Get in touch to book an appointment, seek consultation, or discuss your requirements. Reach out to Pandit Murli Shashtri and experience his expertise and profound knowledge in various services including Akhand Ramayan Path, Annaprashan Sanskar, Ayudha Puja, and more."
};
export const astrologyMetaData = {
    "metaKeywords": "Astrology Services, Vedic Astrology, Astrologer in Jaipur, Astrological Predictions, Birth Chart Analysis, Horoscope Reading",
    "metaDescription": "Explore the world of astrology with Pandit Murli Shashtri, an experienced astrologer in Jaipur. Discover the power of Vedic astrology and unlock insights about your life through accurate birth chart analysis, personalized horoscope readings, and astrological predictions. Gain a deeper understanding of yourself, your relationships, and your future with the guidance of Pandit Murli Shashtri"
};
export const services = {
    "akhandRamayanPath": {
        "name": "Akhand Ramayan Path",
        "img": "/assets/img/services/akhandRamayanPath.png",
        "fev": true,
        "order": "1",
        "metaKeywords": "Book Pandit, Pandit Ji for Akhand Ramayan Path, Akhand Ramayan Path in Jaipur, Pandit Murli Shashtri",
        "metaDescription": "Book Pandit Murli Shashtri Ji for Akhand Ramayan Path in Jaipur and experience the divine recitation of the Ramayana. Get the blessings of Lord Rama through this continuous chanting of the sacred scriptures. Pandit Murli Shashtri Ji will ensure the proper rituals and arrangements for a spiritually enriching Akhand Ramayan Path."

    },
    "annaprashanSanskar": {
        "name": "Annaprashan Sanskar",
        "img": "/assets/img/services/annaprashanSanskar.png",
        "fev": true,
        "order": "4",
        "metaKeywords": "Book Pandit, Pandit Ji for Annaprashan Sanskar, Annaprashan Sanskar in Jaipur, Pandit Murli Shashtri",
        "metaDescription": "Book Pandit Murli Shashtri Ji for Annaprashan Sanskar in Jaipur and celebrate your child's first solid food ceremony with auspicious rituals. Pandit Murli Shashtri Ji will guide you through the sacred rituals and ensure a blessed and memorable Annaprashan Sanskar for your little one."
    },
    "ayudhaPuja": {
        "name": "Ayudha Puja",
        "img": "/assets/img/services/ayudhaPuja.png",
        "fev": true,
        "order": "",
        "metaKeywords": "Book Pandit, Pandit Ji for Ayudha Puja, Ayudha Puja in Jaipur, Pandit Murli Shashtri",
        "metaDescription": "Book Pandit Murli Shashtri Ji for Ayudha Puja in Jaipur and seek the blessings of the divine for the prosperity and success of your tools and instruments. Experience the traditional rituals and worship of tools and vehicles under the guidance of Pandit Murli Shashtri Ji for a blessed Ayudha Puja."
    },
    "bhoomiPuja": {
        "name": "Bhoomi Puja",
        "img": "/assets/img/services/bhoomiPuja.png",
        "fev": true,
        "order": "2",
        "metaKeywords": "Book Pandit, Pandit Ji for Bhoomi Puja, Bhoomi Puja in Jaipur, Pandit Murli Shashtri",
        "metaDescription": "Book Pandit Murli Shashtri Ji for Bhoomi Puja in Jaipur and perform the sacred ceremony of consecrating the land before commencing any construction. Seek the blessings of the divine and ensure the proper rituals and muhurat with the guidance of Pandit Murli Shashtri Ji for a successful Bhoomi Puja."
    },
    "birthdayPuja": {
        "name": "Birthday Puja",
        "img": "/assets/img/services/birthdayPuja.png",
        "fev": true,
        "order": "",
        "metaKeywords": "Book Pandit, Pandit Ji for Birthday Puja, Birthday Puja in Jaipur, Pandit Murli Shashtri",
        "metaDescription": "Book Pandit Murli Shashtri Ji for Birthday Puja in Jaipur and make your special day more auspicious with divine blessings. Celebrate your birthday with traditional rituals and prayers guided by Pandit Murli Shashtri Ji for a blessed and prosperous year ahead."

    },
    "chandiHoma": {
        "name": "Chandi Homa",
        "img": "/assets/img/services/chandiHoma.png",
        "fev": true,
        "order": "",
        "metaKeywords": "Book Pandit, Pandit Ji for Chandi Homa, Chandi Homa in Jaipur, Pandit Murli Shashtri",
        "metaDescription": "Book Pandit Murli Shashtri Ji for Chandi Homa in Jaipur and invoke the blessings of Goddess Durga through the sacred fire ritual. Experience the power of Chandi Homa and receive divine blessings for protection, courage, and prosperity under the expert guidance of Pandit Murli Shashtri Ji."
    },
    "dhanterasPuja": {
        "name": "Dhanteras Puja",
        "img": "/assets/img/services/dhanterasPuja.png",
        "fev": true,
        "order": "",
        "metaKeywords": "Book Pandit, Pandit Ji for Dhanteras Puja, Dhanteras Puja in Jaipur, Pandit Murli Shashtri",
        "metaDescription": "Book Pandit Murli Shashtri Ji for Dhanteras Puja in Jaipur and invoke the blessings of Goddess Lakshmi and Lord Dhanvantari for wealth, prosperity, and good health. Perform the traditional rituals and worship of gold, silver, and other"

    },
    "diwaliLakshmiPuja": {
        "name": "Dhanteras Puja",
        "img": "/assets/img/services/diwaliLakshmiPuja.png",
        "fev": true,
        "order": "",
        "metaKeywords": "Book Pandit, Pandit Ji for Diwali Lakshmi Puja, Diwali Lakshmi Puja in Jaipur, Pandit Murli Shashtri",
        "metaDescription": "Book Pandit Murli Shashtri Ji for Diwali Lakshmi Puja in Jaipur and invite the divine presence of Goddess Lakshmi into your home. Experience the traditional rituals and sacred prayers to seek wealth, prosperity, and abundance in your life. Pandit Murli Shashtri Ji will guide you through the auspicious Diwali Lakshmi Puja with devotion and reverence."

    },
    "ganeshaChaturthiPuja": {
        "name": "Ganesha Chaturthi Puja",
        "img": "/assets/img/services/ganeshaChaturthiPuja.png",
        "fev": false,
        "order": "",
        "metaKeywords": "Book Pandit, Pandit Ji for Ganesha Chaturthi Puja, Ganesha Chaturthi Puja in Jaipur, Pandit Murli Shashtri",
        "metaDescription": "Book Pandit Murli Shashtri Ji for Ganesha Chaturthi Puja in Jaipur and celebrate the birth of Lord Ganesha with devotion and joy. Perform the traditional rituals and prayers to seek the blessings of Lord Ganesha for wisdom, success, and removal of obstacles. Pandit Murli Shashtri Ji will ensure the auspiciousness of Ganesha Chaturthi Puja."

    },
    "gayatriJapa": {
        "name": "Gayatri Japa",
        "img": "/assets/img/services/gayatriJapa.png",
        "fev": false,
        "order": "3",
        "metaKeywords": "Book Pandit, Pandit Ji for Gayatri Japa, Gayatri Japa in Jaipur, Pandit Murli Shashtri",
        "metaDescription": "Book Pandit Murli Shashtri Ji for Gayatri Japa in Jaipur and embark on a spiritual journey of chanting the sacred Gayatri Mantra. Experience the divine vibrations and invoke the blessings of Goddess Gayatri for wisdom, enlightenment, and spiritual growth. Pandit Murli Shashtri Ji will guide you in performing the Gayatri Japa with sincerity and devotion."

    },
    "grahaDoshNivaranPuja": {
        "name": "Graha Dosh Nivaran Puja",
        "img": "/assets/img/services/grahaDoshNivaranPuja.png",
        "fev": false,
        "order": "",
        "metaKeywords": "Book Pandit, Pandit Ji for Graha Dosh Nivaran Puja, Graha Dosh Nivaran Puja in Jaipur, Pandit Murli Shashtri",
        "metaDescription": "Book Pandit Murli Shashtri Ji for Graha Dosh Nivaran Puja in Jaipur and seek remedies for planetary afflictions and doshas in your horoscope. Perform the powerful rituals and prayers to mitigate the malefic effects of grahas and restore harmony in your life. Pandit Murli Shashtri Ji will provide expert guidance for Graha Dosh Nivaran Puja."

    },
    "grihaPraveshaPuja": {
        "name": "Griha Pravesha Puja",
        "img": "/assets/img/services/grihaPraveshaPuja.png",
        "fev": false,
        "order": "",
        "metaKeywords": "Book Pandit, Pandit Ji for Griha Pravesha Puja, Griha Pravesha Puja in Jaipur, Pandit Murli Shashtri",
        "metaDescription": "Book Pandit Murli Shashtri Ji for Griha Pravesha Puja in Jaipur and perform the sacred housewarming ceremony for your new home. Invoke the blessings of the deities and ensure the auspiciousness of your new abode. Pandit Murli Shashtri Ji will conduct the Griha Pravesha Puja with proper rituals and blessings."

    },
    "kalasaPradoshaPuja": {
        "name": "Kalasa Pradosha Puja",
        "img": "/assets/img/services/kalasaPradoshaPuja.png",
        "fev": false,
        "order": "",
        "metaKeywords": "Book Pandit, Pandit Ji for Kalasa Pradosha Puja, Kalasa Pradosha Puja in Jaipur, Pandit Murli Shashtri",
        "metaDescription": "Book Pandit Murli Shashtri Ji for Kalasa Pradosha Puja in Jaipur and seek the divine grace of Lord Shiva during the auspicious Pradosha period. Perform the rituals and prayers with devotion to attain blessings, spiritual growth, and removal of obstacles. Pandit Murli Shashtri Ji will guide you in performing the Kalasa Pradosha Puja with utmost reverence."

    },
    "lakshmiPuja": {
        "name": "Lakshmi Puja",
        "img": "/assets/img/services/lakshmiPuja.png",
        "fev": false,
        "order": "",
        "metaKeywords": "Book Pandit, Pandit Ji for Lakshmi Puja, Lakshmi Puja in Jaipur, Pandit Murli Shashtri",
        "metaDescription": "Book Pandit Murli Shashtri Ji for Lakshmi Puja in Jaipur and worship Goddess Lakshmi to seek wealth, prosperity, and abundance in your life. Perform the traditional rituals and prayers with devotion to invite the blessings of the divine. Pandit Murli Shashtri Ji will ensure the proper conduct of the Lakshmi Puja for your auspiciousness."

    },
    "lastRites": {
        "name": "Last Rites",
        "img": "/assets/img/services/lastRites.png",
        "fev": false,
        "order": "",
        "metaKeywords": "Book Pandit, Pandit Ji for Last Rites, Last Rites in Jaipur, Pandit Murli Shashtri",
        "metaDescription": "Book Pandit Murli Shashtri Ji for Last Rites in Jaipur and perform the final rituals with reverence and respect. Seek proper guidance and support during this challenging time to conduct the last rites of your loved ones. Pandit Murli Shashtri Ji will assist you in performing the Last Rites with the utmost care and adherence to religious customs."

    },
    "mahaGanapatiPujaAndHoma": {
        "name": "Maha Ganapati Puja And Homa",
        "img": "/assets/img/services/mahaGanapatiPujaAndHoma.png",
        "fev": false,
        "order": "",
        "metaKeywords": "Book Pandit, Pandit Ji for Maha Ganapati Puja And Homa, Maha Ganapati Puja And Homa in Jaipur, Pandit Murli Shashtri",
        "metaDescription": "Book Pandit Murli Shashtri Ji for Maha Ganapati Puja And Homa in Jaipur and seek the blessings of Lord Ganesha for auspicious beginnings, success, and removal of obstacles. Perform the grand rituals and sacred fire ceremony to invoke the divine presence of Lord Ganesha. Pandit Murli Shashtri Ji will guide you in performing the Maha Ganapati Puja And Homa with devotion and devotion."

    },
    "mahaMrityunjayaHoma": {
        "name": "Maha Mrityunjaya Homa",
        "img": "/assets/img/services/mahaMrityunjayaHoma.png",
        "fev": false,
        "order": "",
        "metaKeywords": "Book Pandit, Pandit Ji for Maha Mrityunjaya Homa, Maha Mrityunjaya Homa in Jaipur, Pandit Murli Shashtri",
        "metaDescription": "Book Pandit Murli Shashtri Ji for Maha Mrityunjaya Homa in Jaipur and invoke the blessings of Lord Shiva for protection, good health, and longevity. Perform the sacred fire ceremony and recite the powerful Maha Mrityunjaya Mantra for divine intervention and healing. Pandit Murli Shashtri Ji will guide you in conducting the Maha Mrityunjaya Homa with devotion and reverence."

    },
    "marriagePuja": {
        "name": "Marriage Puja",
        "img": "/assets/img/services/marriagePuja.png",
        "fev": false,
        "order": "",
        "metaKeywords": "Book Pandit, Pandit Ji for Marriage Puja, Marriage Puja in Jaipur, Pandit Murli Shashtri",
        "metaDescription": "Book Pandit Murli Shashtri Ji for Marriage Puja in Jaipur and seek the blessings of the divine for a blissful and harmonious married life. Perform the sacred rituals and prayers to invoke the blessings of the deities for a successful and prosperous marriage. Pandit Murli Shashtri Ji will guide you through the auspicious Marriage Puja with utmost care and attention."

    },
    "mulaNakshatraShantiPuja": {
        "name": "Mula Nakshatra Shanti Puja",
        "img": "/assets/img/services/mulaNakshatraShantiPuja.png",
        "fev": false,
        "order": "",
        "metaKeywords": "Book Pandit, Pandit Ji for Mula Nakshatra Shanti Puja, Mula Nakshatra Shanti Puja in Jaipur, Pandit Murli Shashtri",
        "metaDescription": "Book Pandit Murli Shashtri Ji for Mula Nakshatra Shanti Puja in Jaipur and seek the pacification of the malefic effects of the Mula Nakshatra. Perform the rituals and prayers with devotion to alleviate the negative influences and bring harmony and positivity into your life. Pandit Murli Shashtri Ji will guide you in performing the Mula Nakshatra Shanti Puja for your well-being."

    },
    "namakaranPuja": {
        "name": "Namakaran Puja",
        "img": "/assets/img/services/namakaranPuja.png",
        "fev": false,
        "order": "",
        "metaKeywords": "Book Pandit, Pandit Ji for Namakaran Puja, Namakaran Puja in Jaipur, Pandit Murli Shashtri",
        "metaDescription": "Book Pandit Murli Shashtri Ji for Namakaran Puja in Jaipur and perform the sacred naming ceremony for your newborn baby. Seek the blessings of the deities and choose an auspicious name for your child. Pandit Murli Shashtri Ji will conduct the Namakaran Puja with traditional rituals and ensure the auspiciousness of the ceremony."

    },
    "navagrahaPujaAndHoma": {
        "name": "Navagraha Puja and Homa",
        "img": "/assets/img/services/navagrahaPujaAndHoma.png",
        "fev": false,
        "order": "",
        "metaKeywords": "Book Pandit, Pandit Ji for Navagraha Puja and Homa, Navagraha Puja and Homa in Jaipur, Pandit Murli Shashtri",
        "metaDescription": "Book Pandit Murli Shashtri Ji for Navagraha Puja and Homa in Jaipur and seek the blessings and appeasement of the nine celestial planets. Perform the rituals and sacred fire ceremony to mitigate the malefic effects of the planets and enhance their positive influences in your life. Pandit Murli Shashtri Ji will guide you in performing the Navagraha Puja and Homa for planetary harmony."

    },
    "navarathriPath": {
        "name": "Navarathri Path",
        "img": "/assets/img/services/navarathriPath.png",
        "fev": false,
        "order": "",
        "metaKeywords": "Book Pandit, Pandit Ji for Navarathri Path, Navarathri Path in Jaipur, Pandit Murli Shashtri",
        "metaDescription": "Book Pandit Murli Shashtri Ji for Navarathri Path in Jaipur and embark on a spiritual journey of devotion and worship during the auspicious Navaratri festival. Recite the sacred mantras, perform the rituals, and seek the blessings of the divine mother goddesses. Pandit Murli Shashtri Ji will guide you in performing the Navarathri Path with utmost devotion and reverence."

    },
    "officeOpeningOrNewBusinessPuja": {
        "name": "Office Opening or New Business Puja",
        "img": "/assets/img/services/officeOpeningOrNewBusinessPuja.png",
        "fev": false,
        "order": "",
        "metaKeywords": "Book Pandit, Pandit Ji for Office Opening Puja, New Business Puja in Jaipur, Pandit Murli Shashtri",
        "metaDescription": "Book Pandit Murli Shashtri Ji for Office Opening or New Business Puja in Jaipur and invoke the blessings of the divine for success, prosperity, and growth in your new venture. Perform the rituals and prayers to seek divine grace and positive energy for your office or business establishment. Pandit Murli Shashtri Ji will conduct the Office Opening or New Business Puja with proper rituals and blessings."
    },
    "rudrabhishekPuja": {
        "name": "Rudrabhishek Puja",
        "img": "/assets/img/services/rudrabhishekPuja.png",
        "fev": false,
        "order": "",
        "metaKeywords": "Book Pandit, Pandit Ji for Rudrabhishek Puja, Rudrabhishek Puja in Jaipur, Pandit Murli Shashtri",
        "metaDescription": "Book Pandit Murli Shashtri Ji for Rudrabhishek Puja in Jaipur and seek the blessings and grace of Lord Shiva. Perform the sacred rituals and offerings to Lord Shiva and seek divine blessings for happiness, peace, and prosperity. Pandit Murli Shashtri Ji will guide you in performing the Rudrabhishek Puja with devotion and reverence."

    },
    "sathyanarayanPuja": {
        "name": "Sathyanarayan Puja",
        "img": "/assets/img/services/sathyanarayanPuja.png",
        "fev": false,
        "order": "",
        "metaKeywords": "Book Pandit, Pandit Ji for Sathyanarayan Puja, Sathyanarayan Puja in Jaipur, Pandit Murli Shashtri",
        "metaDescription": "Book Pandit Murli Shashtri Ji for Sathyanarayan Puja in Jaipur and perform the auspicious puja to seek the blessings of Lord Vishnu. Conduct the rituals, offer prayers, and perform the storytelling of Lord Sathyanarayan Katha for prosperity, happiness, and fulfillment of desires. Pandit Murli Shashtri Ji will guide you in performing the Sathyanarayan Puja with devotion and piety."

    },
    "sudarshanaHoma": {
        "name": "Sudarshana Homa",
        "img": "/assets/img/services/sudarshanaHoma.png",
        "fev": false,
        "order": "",
        "metaKeywords": "Book Pandit, Pandit Ji for Sudarshana Homa, Sudarshana Homa in Jaipur, Pandit Murli Shashtri",
        "metaDescription": "Book Pandit Murli Shashtri Ji for Sudarshana Homa in Jaipur and invoke the blessings of Lord Sudarshana, the divine weapon of Lord Vishnu. Perform the sacred fire ceremony to seek protection, removal of obstacles, and spiritual upliftment. Pandit Murli Shashtri Ji will guide you in performing the Sudarshana Homa with devotion and reverence."

    },
    "sunderkandPath": {
        "name": "Sunderkand Path",
        "img": "/assets/img/services/sunderkandPath.png",
        "fev": false,
        "order": "",
        "metaKeywords": "Book Pandit, Pandit Ji for Sunderkand Path, Sunderkand Path in Jaipur, Pandit Murli Shashtri",
        "metaDescription": "Book Pandit Murli Shashtri Ji for Sunderkand Path in Jaipur and embark on a spiritual journey through the beautiful verses of the Sunderkand. Recite the sacred hymns and narrations of the epic Ramayana, focusing on the valiant acts of Lord Hanuman. Pandit Murli Shashtri Ji will guide you in performing the Sunderkand Path with devotion and dedication."

    },
    "varaMahalakshmiPuja": {
        "name": "Vara Mahalakshmi Puja",
        "img": "/assets/img/services/varaMahalakshmiPuja.png",
        "fev": false,
        "order": "",
        "metaKeywords": "Book Pandit, Pandit Ji for Vara Mahalakshmi Puja, Vara Mahalakshmi Puja in Jaipur, Pandit Murli Shashtri",
        "metaDescription": "Book Pandit Murli Shashtri Ji for Vara Mahalakshmi Puja in Jaipur and seek the blessings of Goddess Mahalakshmi for wealth, prosperity, and happiness. Perform the rituals and prayers dedicated to the divine mother on the auspicious Vara Mahalakshmi Vratham. Pandit Murli Shashtri Ji will guide you in conducting the Vara Mahalakshmi Puja with devotion and reverence."

    }
}