import React from "react";
import CommentForm from "./CommentForm";
import { serviceComments } from "../../../constants/comments";

function Comments(props) {
    const { serviceKey } = props;
    const comments = serviceComments[serviceKey];
    const customerImage = '/assets/img/user.jpg';
    return (<>
        <div className="single-comment">
            <h2>{comments.length > 0 ? comments.length : "No"} Comments</h2>
            <ul className="comment-list">
                {
                    comments.map(function (comment, index) {
                        return (
                            <li className="comment-item" key={index} >
                                <div className="comment-body">
                                    <div className="comment-img">
                                        <img src={customerImage} alt="customerImage" />
                                    </div>
                                    <div className="comment-text">
                                        <h3>{comment.name}</h3>
                                        <span>{comment.createdAt}</span>
                                        <p>
                                            {comment.message}
                                        </p>
                                    </div>
                                </div>
                            </li>
                        );
                    })
                }
            </ul>
        </div>
        <div className="comment-form">
            <h2>Leave a comment</h2>
            <CommentForm></CommentForm>
        </div></>)
}
export default Comments;