export const testimonials = [
    {
        "name": "Rajesh Kumar",
        "subText": "Jaipur",
        "message": "I recently had a Griha Pravesh Puja performed by them, and I was amazed at the attention to detail and the positive energy they brought. Highly recommended!"
    },
    {
        "name": "Meena Sharma",
        "subText": "Sanganeer",
        "message": "The Rudrabhishek Puja conducted by their knowledgeable priests was a truly divine experience. It brought peace and harmony into my life."
    },
    {
        "name": "Ravi Patel",
        "subText": "Ajmer",
        "message": "The Navaratri Path organized by them was a wonderful celebration of the divine mother. The chants and rituals created a spiritual atmosphere that filled my heart with joy."
    },
    {
        "name": "Priya Singhania",
        "subText": "Mansarover",
        "message": "I availed their Office Opening Puja service, and it made a significant difference in the positive energy of my new workspace. I highly recommend their services for a successful start to any business."
    },
    {
        "name": "Ram Sahay Sharma",
        "subText": "Bagru",
        "message": "The Sudarshana Homa performed by their team was a powerful spiritual experience. I felt a sense of protection and positivity after the homa. Truly transformative!"
    },
    {
        "name": "Ramji Lal Choudhary",
        "subText": "Gangati Kalan",
        "message": "I had the privilege of attending the Sunderkand Path organized by them. The enchanting recitation of Hanuman Chalisa filled me with devotion and brought immense peace to my mind."
    },
    {
        "name": "Kanhiya Lal Mali",
        "subText": "Mozmabad",
        "message": "The Vara Mahalakshmi Puja performed by their priests was conducted with utmost reverence and devotion. It invoked blessings of Goddess Lakshmi and brought prosperity to my family."
    },
    {
        "name": "Swati Sharma",
        "subText": "Phagi",
        "message": "I highly recommend their Namakaran Puja service. The panditji conducted the ceremony with precision and explained the significance of each ritual. It was a memorable event for our family."
    },
    {
        "name": "Rahul Shekhawat",
        "subText": "Sikar",
        "message": "The Navagraha Puja and Homa conducted by their expert priests helped me overcome planetary challenges and brought harmony into my life. I am grateful for their guidance."
    },
    {
        "name": "Ajay Kumawat",
        "subText": "Jaipur",
        "message": "The Mula Nakshatra Shanti Puja organized by them was a profound spiritual experience. It helped alleviate the negative influences of my birth star and brought peace to my life."
    },
]