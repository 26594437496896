import { Link } from "react-router-dom";

function ServiceShort(props) {
    return (
        <div className="service-item">
            <i className="flaticon-car" />
            <h3><Link to={props.link}>{props.name}</Link></h3>
            <p>{props.shortDescription}</p>
        </div>
    );
}
export default ServiceShort;
