import React from "react";
import Topheadbar from "./header/Topheadbar";
import NavBar from "./header/NavBar";
import Footer from "./footer/Footer";
import Script from "react-load-script";

class Layout extends React.Component {
    state = { scriptLoaded: false };

    handleScriptError() {
        console.log("Error while loading script");
    }

    handleScriptLoad() {
        console.log("Script loaded successfully");
        this.setState({ scriptLoaded: true });
    }
    render() {

        return (
            <>
                {/* Top Bar Start */}
                <Topheadbar />
                {/* Top Bar End */}

                {/* Nav Bar Start */}
                <NavBar />
                {/* Nav Bar End  */}

                <main>{this.props.children}</main>

                <Footer />
                <Script
                    url="/assets/js/main.js"
                    onError={this.handleScriptError.bind(this)}
                    onLoad={this.handleScriptLoad.bind(this)}
                />
                {/* <Demo ready={this.state.scriptLoaded} /> */}
            </>
        )
    }
}

export default Layout;