import { t } from 'i18next'
import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'
import { companyName, companySite, email, mobile, siteName } from '../../../constants/global'

export class Footer extends Component {
  render() {
    return (
      <div>
        <div className="footer">
          <div className="container">
            <div className="row">
              <div className="col-lg-3 col-md-6">
                <div className="footer-contact">
                  <h2>{t('contactUs.getInTouch')}</h2>
                  <p>
                    <i className="fa fa-map-marker-alt"></i>#15, Prem Nagar,
                    Near Takshashila School,
                    Malpura road,
                    Madarampura, Sanganer,
                    Jaipur(Raj)-302029

                  </p>
                  <p>
                    <i className="fa fa-phone-alt"></i><a href={`tel:${mobile}`}>{mobile}</a>
                  </p>
                  <p>
                    <i className="fa fa-envelope"></i><a href={`mailto:${email}`}>{email}</a>
                  </p>
                  {/* <div className="footer-social">
                    <a className="btn" href="/">
                      <i className="fab fa-twitter"></i>
                    </a>
                    <a className="btn" href="/">
                      <i className="fab fa-facebook-f"></i>
                    </a>
                    <a className="btn" href="/">
                      <i className="fab fa-youtube"></i>
                    </a>
                    <a className="btn" href="/">
                      <i className="fab fa-instagram"></i>
                    </a>
                    <a className="btn" href="/">
                      <i className="fab fa-linkedin-in"></i>
                    </a>
                  </div> */}
                </div>
              </div>
              <div className="col-lg-3 col-md-6">
                <div className="footer-link">
                  <h2>Popular Links</h2>
                  <NavLink to="/aboutUs" className="nav-item nav-link">
                    {t('navigation.aboutUs')}
                  </NavLink>
                  <NavLink to="/contactUs" className="nav-item nav-link">
                    {t('navigation.contactUs')}
                  </NavLink>
                  <NavLink to="/services" className="nav-item nav-link">
                    {t('navigation.prayerServices')}
                  </NavLink>
                  <NavLink to="/astrology" className="nav-item nav-link">
                    {t('navigation.astrologyServices')}
                  </NavLink>
                </div>
              </div>
              <div className="col-lg-3 col-md-6">
                <div className="footer-link">
                  <h2>Useful Links</h2>
                  <a href="/">Terms of use</a>
                  <a href="/">Privacy policy</a>
                  <a href="/">Cookies</a>
                  <a href="/">Help</a>
                  <a href="/">FQAs</a>
                </div>
              </div>
              <div className="col-lg-3 col-md-6">

                <div className="footer-social">
                  <a className="btn" href="/">
                    <i className="fab fa-twitter"></i>
                  </a>
                  <a className="btn" href="/">
                    <i className="fab fa-facebook-f"></i>
                  </a>
                  <a className="btn" href="/">
                    <i className="fab fa-youtube"></i>
                  </a>
                  <a className="btn" href="/">
                    <i className="fab fa-instagram"></i>
                  </a>
                  <a className="btn" href="/">
                    <i className="fab fa-linkedin-in"></i>
                  </a>

                </div>
              </div>
            </div>
          </div>
          <div className="container copyright">
            <p>
              &copy; <a href="/">{siteName}</a>, All Right Reserved.
              Designed By <a href={`${companySite}`} target="_blank" rel="noreferrer">{companyName}</a>
            </p>
          </div>
        </div>
      </div>
    )
  }
}

export default Footer
