import React from "react";

function CommentForm(props) {
    return (<>
        <form>
            <div className="form-group">
                <label htmlFor="name">Name *</label>
                <input type="text" className="form-control" id="name" />
            </div>
            <div className="form-group">
                <label htmlFor="mobile">mobile *</label>
                <input type="mobile" className="form-control" id="mobile" />
            </div>
            <div className="form-group">
                <label htmlFor="message">Message *</label>
                <textarea id="message" cols="30" rows="5" className="form-control"></textarea>
            </div>
            <div className="form-group">
                <input type="submit" value="Post Comment" className="btn btn-custom" />
            </div>
        </form>
    </>)
}
export default CommentForm;