import React, { Component } from 'react'
import Map from "./Map";
import { contactUsMetaData, email, mobile, siteName } from '../../constants/global'
import ServiceSelect from '../common/service-select/service-select';
import { t } from 'i18next';
import { Helmet, HelmetProvider } from 'react-helmet-async';

export class ContactUs extends Component {
    constructor(props) {
        super(props);
        this.metaKeywords = contactUsMetaData.metaKeywords;
        this.metaDescription = contactUsMetaData.metaDescription;
    }
    render() {
        return (
            <><HelmetProvider>
                <Helmet>
                    <title>{t('navigation.contactUs')} | {siteName}</title>
                    <meta name="keywords" content={this.metaKeywords} />
                    <meta name="description" content={this.metaDescription} />
                </Helmet>
                <div className="contact">
                    <div className="container">
                        <div className="section-header text-center">
                            <p>{t('contactUs.getInTouch')}</p>
                            <h2>{t('contactUs.contactFor')}</h2>
                        </div>
                        <div className="row">
                            <div className="col-md-4">
                                <div className="contact-info">
                                    <h2>Quick Contact Info</h2>
                                    <div className="contact-info-item">
                                        <div className="contact-info-icon">
                                            <i className="far fa-clock"></i>
                                        </div>
                                        <div className="contact-info-text">
                                            <h3>Opening Hour</h3>
                                            <p>Mon - Sun, 8:00am - 9:00pm</p>
                                        </div>
                                    </div>
                                    <div className="contact-info-item">
                                        <div className="contact-info-icon">
                                            <i className="fa fa-phone-alt"></i>
                                        </div>
                                        <div className="contact-info-text">
                                            <h3>Call Us</h3>
                                            <p><a href={`tel:${mobile}`}>{mobile}</a></p>
                                        </div>
                                    </div>
                                    <div className="contact-info-item">
                                        <div className="contact-info-icon">
                                            <i className="far fa-envelope"></i>
                                        </div>
                                        <div className="contact-info-text">
                                            <h3>Email Us</h3>
                                            <p><a href={`mailto:${email}`}>{email}</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-7">
                                <div className="contact-form">
                                    <div id="success"></div>
                                    <form name="sentMessage" id="contactForm" novalidate1="novalidate">
                                        <div className="control-group">
                                            <input type="text" className="form-control" id="name"
                                                placeholder="Your Name" required="required"
                                                data-validation-required-message="Please enter your name" />
                                            <p className="help-block text-danger"></p>
                                        </div>
                                        <div className="control-group">
                                            <input type="mobile" className="form-control" id="mobile"
                                                placeholder="Your Mobile" required="required"
                                                data-validation-required-message="Please enter your mobile" />
                                            <p className="help-block text-danger"></p>
                                        </div>
                                        <div className="control-group">
                                            <ServiceSelect></ServiceSelect>
                                            <p className="help-block text-danger"></p>
                                        </div>
                                        <div className="control-group">
                                            <input type="date" className="form-control" id="date"
                                                placeholder="Date" required="required"
                                                data-validation-required-message="Please enter a date" />
                                            <p className="help-block text-danger"></p>
                                        </div>
                                        <div className="control-group">
                                            <textarea className="form-control" id="message" placeholder="Message"
                                                required="required"
                                                data-validation-required-message="Please enter your message"></textarea>
                                            <p className="help-block text-danger"></p>
                                        </div>
                                        <div>
                                            <button className="btn btn-custom" type="submit" id="sendMessageButton">Send
                                                Message
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <Map />
                            </div>
                        </div>
                    </div>
                </div>
            </HelmetProvider>
            </>
        )
    }
}

export default ContactUs