import { React } from 'react';
import { withTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { t } from 'i18next';
import { services } from '../../../constants/global';
import Anchor from '../../common/anchor/Anchor';
import { serviceNameToUrl } from '../../../services/common';

function NavBar() {
  return (
    <div>
      {/* Nav Bar Start */}
      <div className="nav-bar">
        <div className="container">
          <nav className="navbar navbar-expand-lg bg-dark navbar-dark">
            <NavLink to="/" className="navbar-brand">
              {t('navigation.home')}
            </NavLink>
            <button
              type="button"
              className="navbar-toggler"
              data-toggle="collapse"
              data-target="#navbarCollapse"
            >
              <span className="navbar-toggler-icon" />
            </button>

            <div
              className="collapse navbar-collapse justify-content-between"
              id="navbarCollapse"
            >
              <div className="navbar-nav mr-auto">
                <NavLink to="/" className="nav-item nav-link">
                  {t('navigation.home')}
                </NavLink>
                <NavLink to="/aboutUs" className="nav-item nav-link">
                  {t('navigation.aboutUs')}
                </NavLink>
                <div className="nav-item dropdown ">
                  <NavLink
                    to="/services"
                    className="nav-link dropdown-toggle"
                    data-toggle="dropdown"
                  >
                    {t('navigation.prayerServices')}
                  </NavLink>
                  <div className="dropdown-menu fixed-height">
                    <div className='row'>
                      <div className="col-12">
                        <Anchor href="/services" text={t('navigation.allPrayerServices')}></Anchor>
                      </div>
                      {
                        Object.keys(services).map((key, index) => {
                          return (
                            <div className="col-6" key={index}>
                              <Anchor href={'/services/' + serviceNameToUrl(key)} text={t(`services.${key}.name`)}></Anchor>
                            </div>
                          );
                        })
                      }
                    </div>
                  </div>
                </div>
                <NavLink to="/astrology" className="nav-item nav-link">
                  {t('navigation.astrologyServices')}
                </NavLink>
                <NavLink to="/contactUs" className="nav-item nav-link">
                  {t('navigation.contactUs')}
                </NavLink>
              </div>
              <div className="ml-auto">
                <NavLink to="/contactUs" className="btn btn-custom">
                  {t('navigation.getAppointment')}
                </NavLink>
              </div>
            </div>
          </nav>
        </div>
      </div>
      {/* Nav Bar End  */}
    </div>
  );
}

export default withTranslation()(NavBar);
