import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'
import { email, mobile } from '../../../constants/global'
import { useTranslation } from 'react-i18next';
import useAnalyticsEventTracker from '../../../Analytics';


function Topheadbar() {
  //Calling t and i18n method from useTranslation hook 
  const { i18n } = useTranslation();
  const [language, setLangauge] = useState(localStorage.getItem('langauage') || "en");

  //Creating a method to change the language onChnage from select box
  const changeLanguageHandler = (e) => {
    const languageValue = e.target.value;
    console.log("changeLanguage", languageValue);
    i18n.changeLanguage(languageValue);
    setLangauge(languageValue);
    localStorage.setItem('langauage', languageValue);
  }
  const gaEventTracker = useAnalyticsEventTracker('');
  return (
    <>
      <div className="top-bar">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="logo">
                <NavLink to="/">
                  <h1>
                    Pt. <span> Murli</span>
                  </h1>
                </NavLink>
              </div>
            </div>
            <div className="col-lg-8 col-md-6 col-sm-12">
              <div className="row">
                <div className="offset-lg-4 col-lg-4 col-md-6 col-sm-6 w-0">
                  <div className="top-bar-item">
                    <div className="top-bar-icon">
                      <i className="fa fa-phone-alt"></i>
                    </div>
                    <div className="top-bar-text">
                      <h3>Call Us</h3>
                      <p><a href={`tel:${mobile}`} onClick={() => gaEventTracker('mobile')}>{mobile}</a>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-6 w-0">
                  <div className="top-bar-item">
                    <div className="top-bar-icon">
                      <i className="far fa-envelope"></i>
                    </div>
                    <div className="top-bar-text">
                      <h3>Email Us <select onChange={changeLanguageHandler} style={{ float: 'right' }} value={language} id="language">
                        <option value="en" >En</option>
                        <option value="hi" >Hi</option>
                      </select></h3>
                      <p><a href={`mailto:${email}`}>{email}</a></p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )

}

export default Topheadbar
