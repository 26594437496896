import React, { Component } from 'react'
import { services, siteName } from '../../constants/global';
import ServiceShort from './ServiceShort';
import { t } from 'i18next';
import { serviceNameToUrl } from '../../services/common';
import { Helmet, HelmetProvider } from 'react-helmet-async';

export class Service extends Component {
  render() {
    return (
      <>
        <HelmetProvider>
          <Helmet>
            <title>{t('navigation.prayerServices')} | {siteName}</title>
          </Helmet>

          <div className="service">
            <div className="container">
              <div className="section-header text-center">
                <p>{t('sevicesParagraph')}</p>
                <h2>{t('navigation.prayerServices')}</h2>
              </div>
              <div className="row">
                {
                  Object.keys(services).map((service) => {
                    return (
                      <div className="col-lg-3 col-md-6" key={service}>
                        <ServiceShort link={'/services/' + serviceNameToUrl(service)} name={t(`services.${service}.name`)} shortDescription={t(`services.${service}.short`)} />
                      </div>
                    );

                  })
                }
              </div>
            </div>
          </div>
        </HelmetProvider>
      </>
    );
  }
}

export default Service
