import { Component } from "react";
import { testimonials } from "../../constants/testimonials";

export class Testimonial extends Component {
    render() {
        return (
            <>
                <div className="testimonial">
                    <div className="container">
                        <div className="section-header text-center">
                            <p>Testimonial</p>
                            <h2>What our clients say</h2>
                        </div>
                        <div className="owl-carousel testimonials-carousel">
                            {
                                testimonials.map(function (testmonial, index) {
                                    return (
                                        <div className="testimonial-item" key={index}>
                                            <img src="/assets/img/testimonial-1.png" alt={testmonial.name} />
                                            <div className="testimonial-text">
                                                <h3>{testmonial.name}</h3>
                                                <h4>{testmonial.subText}</h4>
                                                <p>
                                                    {testmonial.message}
                                                </p>
                                            </div>
                                        </div>
                                    )
                                })
                            }

                        </div>
                    </div>
                </div>
            </>
        )
    }
}